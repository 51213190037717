<template>
  <div class="course">
    <van-row class="top">
      <van-col span="16" class="top_left">
        <!-- <van-tag type="warning" size="large">执业药师<van-icon name="play" /></van-tag> -->
        <van-button color="#ffffff" @click="handleClickProject" >{{groupName}}<van-icon name="play" /></van-button>
      </van-col>

      <van-col span="8" class="top_right">
        <van-grid :column-num="2">
          <van-grid-item icon="shopping-cart-o" text="购物车" @click="handleClickShopingCart" />
          <van-grid-item icon="comment-o" text="客服" />
        </van-grid>
      </van-col>
    </van-row>
    <!-- <van-search v-model="SearchValue"  shape="round" placeholder="请输入搜索关键词1" disabled
      @click="handleClickSearch" 
     /> -->
     <transition name="van-slide-right" mode="out-in">
        <router-view></router-view>
      </transition>

    <div class="banner">
      <van-swipe class="my-swipe" :autoplay="3000" :height="120" indicator-color="#1989fa">
        <van-swipe-item v-for="item in banner" :key="item.id">
          <img :src="item.url" width="100%" style="display: block;" alt />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div v-if="shopList.length > 0">
      <van-tabs v-model:active="active"  color="#1989fa" swipeable sticky animated class="shop_page">
        <van-tab :title="item.cate_name" v-for="(item,index) in shopList" :key="index">
          <div class="shoplist" v-for="(item2,index2) in item.data" :key="index2">
            <van-card
                    v-if="branchId ==1 || branchId == 3 ||branchId == 5"
                    class="shop-card"
                    :tag="item2.discount"
                    :desc="item2.commodity_content"
                    :title="item2.title"
                    :thumb="item2.photopath"
                    @click="handleClickCourseDetail(item2.detail_id)"
            >
              <template #tags>
                <div class="tags">
                  <van-tag plain round type="warning" v-for="(item3,index3) in item2.label">{{item3}}</van-tag>
                </div>
              </template>
              <template #bottom>
                <!-- <span class="price">￥{{item2.scribing_price}}</span> -->
                <van-button round type="danger" size="mini" class="audition">了解详情</van-button>
              </template>
            </van-card>
            <van-card
                    v-else
                    class="shop-card"
                    :tag="item2.discount"
                    :desc="item2.commodity_content"
                    :title="item2.title"
                    :thumb="item2.photopath"
                    :origin-price="item2.market_value"
                    @click="handleClickCourseDetail(item2.detail_id)"
            >
              <template #tags>
                <div class="tags">
                  <van-tag plain round type="warning" v-for="(item3,index3) in item2.label">{{item3}}</van-tag>
                </div>
              </template>
              <template #bottom>
                <span class="price">￥{{item2.scribing_price}}</span>
                <van-button round type="danger" size="mini" class="audition">了解详情</van-button>
              </template>
            </van-card>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div v-else>
      <van-empty
              description="暂无商品"
              image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
              image-size="24rem">
<!--        <van-button round color="#1989fa" size="normal" class="bottom-button" icon="plus">选课中心</van-button>-->
      </van-empty>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import axios from "axios"


import {onShareUrl, selectCommodityList, selectCommodityList2} from '@/request/api'
import {Toast} from "vant";
import wx from "weixin-js-sdk";

export default {
  name: 'Course',
  metaInfo() {
    return {
      title: this.title, //设置页面title
    }
  },
  // metaInfo: {
  //     title: '一通教育网校选课中心', //设置页面title
  //     meta: [{                 //设置页面meta
  //         name: 'keyWords',
  //         content: '关键词'
  //       },
  //       {
  //         name: "description",
  //         content: "描述",
  //       },
  //     ]
  // },
  data() {
    return {
      title: this.getBranchCompany(),
      SearchValue: "",
      active: '',
      item: '',
      //轮播图数组
      banner: [],
      show: false,
      
      shopList:[],
      groupName: (localStorage.getItem("groupName") ? localStorage.getItem("groupName") : '未选择'),
      branchId: this.getBranch()
    };
  },
  methods: {
    handleClickSearch() {
      this.$router.push('/course/searchPopup')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handleClickProject() {
      this.$router.push({
        name: 'Project',
        query: {
          redirect: (this.$route.name)
        }
      })
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("show", true)
      // console.log("搜索按钮被点击");
    },
    handleClickCourseDetail(id) {
      if(this.getToken()) {
        this.$router.push({
          name: 'CourseDetail',
          query:{
            id
          }
        })
      } else {
        Toast.fail('请先登录');
        this.$router.push({
          name: 'User',
          query: {redirect: this.$route.name,isShowModal:true}
        })
      }

      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
    },
    handleClickShopingCart() {
      // this.$router.push('/cart')
      // 修改vuex中的 isShowPopupShow 值为true
      // this.$store.commit("changeIsShowPopupShow", true)
      Toast('暂未开放');
    },
    commodityList() {
      let that = this;
      let token = that.getToken();
      if(token) {
        selectCommodityList2()
                .then(res => {
                  if(res.data.code == 1) {
                    that.banner = res.data.data.img
                    that.shopList = res.data.data.list
                  }
                })
                .catch(err => {
                  console.log("error");
                  console.log(err);
                })
      } else {
        let branchId = this.getBranch()
        let groupId = this.getGroupId()
        let subjectId = this.getSubjectId()
        selectCommodityList({branchId,groupId,subjectId})
                .then(res => {
                  if(res.data.code == 1) {
                    that.banner = res.data.data.img
                    that.shopList = res.data.data.list
                    localStorage.setItem("title", res.data.data.company);
                    localStorage.setItem("adminId", res.data.data.admin);
                    that.title = res.data.data.company;
                  }
                })
                .catch(err => {
                  console.log("error");
                  console.log(err);
                })
      }
    },
    onWxConfig() {
      let that = this;
      let branchId = that.getBranch();
      // alert(location.href.split('#')[0]);
      // alert(window.location.origin);
      onShareUrl({url: location.href.split('#')[0],branchId})
              .then(res => {
                if (res.data.code == 1) {
                  wx.config({
                    debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: res.data.data.sdk.appId,         // 必填，公众号的唯一标识，填自己的！
                    timestamp: res.data.data.sdk.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
                    nonceStr: res.data.data.sdk.nonceStr,   // 必填，生成签名的随机串
                    signature: res.data.data.sdk.signature, // 必填，签名，见附录1
                    jsApiList: [
                      'checkJsApi',
                      'onMenuShareQQ',
                      'onMenuShareWeibo',
                      'onMenuShareQZone',
                      'updateAppMessageShareData',
                      'updateTimelineShareData',
                      'onMenuShareTimeline',
                      'onMenuShareAppMessage',
                      'wx-open-launch-weapp'
                    ],
                    openTagList: ['wx-open-launch-weapp']
                  })
                  let shareConfig = {
                    title:  res.data.data.share.fx_share_title,
                    desc: res.data.data.share.fx_share_desc,
                    link:  that.getUrlParameter(),
                    imgUrl: res.data.data.share.fx_share_logo,
                    type: '',
                    dataUrl: '',
                    success: function (r) {
                      console.log("分享成功"+JSON.stringify(rr));
                    },
                    cancel: function (rr) {
                      console.log('分享错误'+JSON.stringify(rr));
                    }
                  }
                  that.getIcoUrl(res.data.data.share.ico);
                  wx.ready(function () {
// that.getHtml();
                    //分享到朋友圈
                    wx.onMenuShareTimeline(shareConfig);
                    //分享给朋友
                    wx.onMenuShareAppMessage(shareConfig);
                    wx.onMenuShareQQ(shareConfig); // QQ
                    wx.updateAppMessageShareData(shareConfig);
                    wx.updateTimelineShareData(shareConfig);
                  });
                  wx.error(function(res){
                    console.log(123);
                    console.log(res);
                    // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
                  })
                }
              })
              .catch(err => {
                console.log("error");
                console.log(err);
              })
    },
  },
  created() {

    this.commodityList();
    this.title = this.getBranchCompany()
    this.onWxConfig();
  },
  mounted() {
    this.title = this.getBranchCompany()
  },
  components: {

  },

}
</script>
<style lang="less">

@import '~@/assets/styles/common.less';

.course {
  background-color: #ffffff;
  // overflow: hidden;
  padding: 0 0.8rem 8rem 0.8rem;
}
.popup-shadow {
  width: 100%;
  height: 100%;
  position: absolute;
  // z-index: 99999;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 20;
}

// .slide-enter, .slide-leave-to {
//   // 过渡之前的样式写在这里
//    right: -100%;
// }
// .slide-enter-active,.slide-leave-active  {
//   // 过渡属性写在这里
//   transition: all .3s;
// }
// .slide-enter-to, .slide-leave {
//   // 过渡之后的样式写在这里
//    right: 0;
// }
  .shop_page .van-sticky--fixed{
    position: fixed;
    top: 5.9rem;
    padding: 0 0.8rem 0.8rem 0.8rem;
    z-index: 9998;
  }
  .shop_page .van-tab--active .van-tab__text {
    padding: 0.4rem 2rem !important;
  }
</style>
